<template>
  <div class="no-top-margin-all-descendants">
    <div class="d-flex flex-row justify-content-between">
      <div class="back-btn-container">
        <b-button v-if="createScenarioStep > 0 && !scenarioSubmissionInProgress" variant="primary" size="sm" @click='goBack'>Back</b-button>
      </div>
      <div class="no-top-margin-no-descendants">
        <div class="step-container">
          <b-icon icon="circle-fill" variant="primary" v-if="createScenarioStep == 0"></b-icon>
          <b-icon icon="check-circle-fill" class="step-control-color" v-else-if="createScenarioStep > 0"></b-icon>
          <b-icon icon="circle-fill" class="step-control-color" v-else></b-icon>
          <p v-bind:class="{ 'step-control-color': createScenarioStep != 0 }">Upload and categorize geometry</p>
        </div>
        <div class="step-container" v-show="!isJobTypeImport">
          <b-icon icon="circle-fill" variant="primary" v-if="createScenarioStep == 1"></b-icon>
          <b-icon icon="check-circle-fill" class="step-control-color" v-else-if="createScenarioStep > 1"></b-icon>
          <b-icon icon="circle-fill" class="step-control-color" v-else></b-icon>
          <p v-bind:class="{ 'step-control-color': createScenarioStep != 1 }">Review geometry integrity</p>
        </div>
        <div class="step-container">
          <b-icon icon="circle-fill" variant="primary" v-if="createScenarioStep == 2"></b-icon>
          <b-icon icon="check-circle-fill" class="step-control-color" v-else-if="createScenarioStep > 2"></b-icon>
          <b-icon icon="circle-fill" class="step-control-color" v-else></b-icon>
          <p v-bind:class="{ 'step-control-color': createScenarioStep != 2 }">Select simulation parameters</p>
        </div>
      </div>  
      <div class="mt-0" v-if="createScenarioStep < 2"> 
        <div v-if="!isJobTypeImport">
          <div id="next-button" class="next-btn-container">
            <b-button v-if="nextButtonEnabled" :variant="(!isGeometryFilesValid && createScenarioStep === 1) ? 'secondary' : 'primary'" size="sm" @click='goToNext'>Next</b-button>
            <b-button v-else disabled variant="primary" size="sm">Next </b-button>
            <b-tooltip v-if='fileUploadErrors || invalidFilename' target='next-button' triggers='hover'>
              There are errors in the file upload form
            </b-tooltip>
            <b-tooltip v-else-if='formUploading' target='next-button' triggers='hover'>
              File upload in progress, please wait until all files are uploaded
            </b-tooltip>
            <b-tooltip v-if='!isGeometryFilesValid && createScenarioStep === 1 && !isJobTypeImport' target='next-button' triggers='hover'>
              Before proceeding, confirm the geometry integrity on your model
            </b-tooltip>
          </div>
        </div>
        <div id='next-button' v-else class="next-btn-container mb-4 mt-0">
          <b-button v-if="createScenarioStep < 2" variant="primary" size="sm" @click="goToNext">Next</b-button>
        </div>
      </div>
      <div class="mt-0" v-else>
        <div v-if='remeshPending' class="bg-dark p-2 mb-2 bottom-right-above-submit-btn"> 
          <b-spinner small class="mr-1 text-white"></b-spinner>
          <p>Refining presentation planes. {{this.remeshedPresentationPlaneCount}}/{{this.presentationPlaneCount}} complete</p>
        </div>

        <div id='submit-button' class="submit-btn-container">
          <b-button
          v-if="createScenarioStep  == 2 && !isJobTypeImport" 
          variant="primary"
          size="sm"
          :disabled="!canSubmit || selectedProject.is_demo_project || scenarioSubmissionInProgress"
          @click='submitScenario()'>
            <b-icon-exclamation-circle class="mr-2" v-if="!canSubmit" />
            <b-spinner small class="mr-2" v-if="scenarioSubmissionInProgress"></b-spinner>
            <span v-if="scenarioSubmissionInProgress">Saving...</span>
            <span v-else>Submit</span>  
          </b-button>

          <b-button class="float-right mb-4" v-if="createScenarioStep == 2 && canSubmitStudyFiles && isJobTypeImport" variant="primary" size="sm" @click="submitImportResults">Submit</b-button> 
          
          <b-button class="float-right mb-4" v-if="createScenarioStep == 2 && !canSubmitStudyFiles && isJobTypeImport" variant="secondary " size="sm"  v-b-tooltip.hover :title=gltfErrorMsg >Submit</b-button> 
          
          <b-tooltip v-if='remeshPending' target='submit-button' triggers='hover'>
            Presentation plane refinement in progress
          </b-tooltip>
          <b-tooltip v-if='!simulationHasAssets' target='submit-button' triggers='hover'>
            Missing geometry for this simulation
          </b-tooltip>
          <b-tooltip v-else-if="simulation.simulation_type === 'ML' && !simulationHasMetData && simulationNeedsMetData" target='submit-button' triggers='hover'>
            Missing meteorological data for this project
          </b-tooltip>
          <b-tooltip v-else-if="simulation.simulation_type === 'ML' && !projectHasCorrectMetDataForSelectedAnalysisTypes && simulationNeedsMetData" target='submit-button' triggers='hover'>
            Selected meteorological data is not appropriate for the selected analysis types
          </b-tooltip>
          <b-tooltip v-if="simulationParametersErrors" target="submit-button" triggers="hover">
            There are errors in the simulation parameters form
          </b-tooltip>
        </div>
      </div>
    </div>

    
    <import-results-confirmation v-if="isJobTypeImport" :modalStatus="modalStatus" @saveSimAssetFiles="saveSimAssetFiles" />

  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { BIcon } from 'bootstrap-vue';
import { EventBus } from '@/network/eventbus';
import ImportResultsConfirmation from './ImportResultsConfirmation.vue';

export default {
  name: 'ScenarioSubmissionStepsPanel',
  components: {
    BIcon,
    ImportResultsConfirmation
  },
  props: {
    gltfSubmitStatus: {
      type: [Boolean,String],
      required: false,
    },
  },
  data() {
    return {
      remeshNotificationCount: 0,
      step: 0,
      modalStatus: 0,
      gltfErrorMsg: 'Please upload study files',
    };
  },
  created() {
    EventBus.$on('REMESHSTL_COMPLETE', this.onRemeshComplete);
    EventBus.$on('MET_FETCH_COMPLETE', this.onFetchMetComplete);
  },
  beforeDestroy() {
    EventBus.$off('REMESHSTL_COMPLETE', this.onRemeshComplete);
    EventBus.$off('MET_FETCH_COMPLETE', this.onFetchMetComplete);
  },
  computed: {
    canSubmitStudyFiles(){
      if(this.gltfSubmitStatus == true){  
        return this.gltfSubmitStatus;
      }
      return false;
    },
    isJobTypeImport() {
      let jobType;
      this.simulation?.job_types_with_criteria?.some(x => {
        jobType =  this.jobTypes?.find(z => z.id === x.job_type);        
      });  
      if(jobType?.label == 'Imports GLTF Results')
        return true;
      return false;
    },
    nextButtonEnabled() {
      return this.geometryFilesUploaded && !this.fileUploadErrors && !this.invalidFilename && !this.formUploading;
    },
    configurationId() {
      return this.$store.getters['project/createdScenarioId'] || this.$store.getters['project/simulationAsset/configurationId'];
    },
    simulationIsPLW() {
      return !this.simulationIsCladding;  //old simulations might not have a default job type so default to PLW if it's not CLADDING (the only other type currently) as all Cladding jobs will have a job type
    },
    remeshPending() {
      if(this.simulation.simulation_type === 'ML' && this.simulationIsPLW) {
        return this.remeshedPresentationPlaneCount < this.presentationPlaneCount;
      // } else if (this.simulation.simulation_type === 'ML' && this.simulationIsCladding) {
      //   return this.remeshedStudyBuildingCount < this.StudyBuildingCount;
      } else {
        return false;
      }
    },
    simulationHasAssets() {
      let asset_types = [];
      if (this.createdScenarioFormValues != null) {
        asset_types = this.getFileNames();
      } else {
        asset_types = this.simulation.assets.map(a => a.geometry_type);
      }
      if(this.simulation.simulation_type === 'CFD') {
        return asset_types.includes('ground')
          && asset_types.includes('presentation plane');
      } else if(this.simulationIsCladding) {
        return asset_types.includes('study')
          && asset_types.includes('surround')
          && asset_types.includes('ground');
      } else if (this.simulationIsImport) {
        return true; //users can import whatever they want, there are no restrictions
      } else {  //simulation_type == ML
        return asset_types.includes('study')
          && asset_types.includes('surround')
          && asset_types.includes('ground')
          && asset_types.includes('presentation plane');
      }
    },
    simulationHasWindDirections() {
      if(this.simulation.simulation_type === 'ML') {
        return !!this.simulation.ml_parameters.windDirections?.length;
      } else {
        return true;
      }
    },
    simulationHasMetData() {
      let met_source_id_for_simulation = this.simulation.met_source_id;
      let met_source = this.selectedProject.met_sources.find(x => x.id == met_source_id_for_simulation);
      if (met_source) {
        if (met_source.wind_met_data_id != null && met_source.error == null) {
          return true;
        }        
        if (met_source.custom_met_data?.file) {
          return true;
        }
      }

      return false;
    },
    simulationNeedsMetData() {

      if (this.simulationIsCladding) {
        //cladding sims only need MET data for specific metrics
        // if simulation requires ACK file (for UpX) return true
        let allCriteria = this.simulation.job_types_with_criteria[0].criteria;
        let requiresMetData = false;
        allCriteria.forEach(criteriaID => {
          let selectedCriteria = this.criteria.find(criterion => criterion.id === criteriaID);
          for (let key in selectedCriteria?.required_params) {
            if (key == 'ack_file' && selectedCriteria.required_params[key]=='required') {
              requiresMetData = true;
            }
          }
        });
        return requiresMetData;

      }

      //other job types always require MET
      return true;
    },
    projectHasCorrectMetDataForSelectedAnalysisTypes() {
      //check each analysis type to see if we have the required MET data for it
      for (let jobTypeWithCriteria of this.simulation.job_types_with_criteria) {
        let jobType = this.jobTypes.find(jt => jt.id === jobTypeWithCriteria.job_type);
        let hasMet = this.analysisTypeHasRequiredMetSource(jobType.analysis_type);

        if (!hasMet) {
          return false;
        }
      }  

      return true;

    },
    
    remeshedPresentationPlaneCount() {
      let completeOnLoad = this.simulation.assets.filter(a => a.geometry_type === 'presentation plane' && a.remeshed_assets.length).length;
      return completeOnLoad + this.remeshNotificationCount;
    },
    // remeshedStudyBuildingCount() {
    //   let completeOnLoad = this.simulation.assets.filter(a => a.geometry_type === 'study' && a.remeshed_assets.length).length;
    //   return completeOnLoad + this.remeshNotificationCount;
    // },
    presentationPlaneCount() {
      return this.simulation.assets.filter(a => a.geometry_type === 'presentation plane').length;
    },
    // StudyBuildingCount() {
    //   return this.simulation.assets.filter(a => a.geometry_type === 'study').length;
    // },
    errorsInSimulationParametersForm() {
      let invalidReturnPeriodExists = false;
      for (let criteria of Object.keys(this.invalidReturnPeriods)) {
        if (this.invalidReturnPeriods[criteria] == true) {
          console.log(`invalid return period in: ${criteria}`);
          invalidReturnPeriodExists = true;
        }
      }

      if (this.simulationParametersErrors
      || this.fileUploadErrors
      || this.invalidFilename
      || this.invalidSeasons
      || this.invalidTimes
      || this.invalidWinds
      || this.invalidSingleDate
      || this.invalidSingleDayHours
      || this.invalidMinutesInterval
      || invalidReturnPeriodExists) {

        console.log(`simulationParametersErrors: ${this.simulationParametersErrors}`);
        console.log(`fileUploadErrors: ${this.fileUploadErrors}`);
        console.log(`invalidSeasons: ${this.invalidSeasons}`);
        console.log(`invalidTimes: ${this.invalidTimes}`);
        console.log(`invalidWinds: ${this.invalidWinds}`);
        console.log(`invalidSingleDate: ${this.invalidSingleDate}`);
        console.log(`invalidSingleDayHours: ${this.invalidSingleDayHours}`);
        console.log(`invalidMinutesInterval: ${this.invalidMinutesInterval}`);
        return true;
      }

      return false;
    },
    getGLTFErrorMsg(){
      return this.gltfErrorMsg;
    },
    canSubmit() {
      if (this.remeshPending
      || !this.simulationHasAssets
      || (this.simulationNeedsMetData && !this.simulationHasMetData)
      || this.errorsInSimulationParametersForm
      || (this.simulationNeedsMetData && !this.projectHasCorrectMetDataForSelectedAnalysisTypes)) {
        return false;
      } else {
        return true;
      }
    },
    simulationIsCladding() {
      let jobType;
      return this.simulation.job_types_with_criteria?.some(x => {
        jobType = this.jobTypes.find(z => z.id === x.job_type);
        return jobType ? jobType.sub_engine === 'Cladding' : false;
      });
    },
    simulationIsImport() {
      let jobType;
      return this.simulation.job_types_with_criteria?.some(x => {
        jobType = this.jobTypes.find(z => z.id === x.job_type);
        return jobType ? jobType.engine === 'Import' : false;
      });
    },
    isGeometryFilesValid() {
      if (this.geometryValidationSteps) {
        return Object.entries(this.geometryValidationSteps).filter(([key, value]) => { 
          if(this.simulationIsCladding && key === 'presentation_surfaces') return false;
          return key !== 'id' && value === false;
        }).length === 0;
      } else {
        return false;
      }
    },
    ...mapGetters('project/simulationAsset', ['simulation', 'geometryValidationSteps']),
    ...mapGetters('project/viewer', ['geometryFilesUploaded']),
    ...mapGetters('project', ['createScenarioStep', 'createdScenarioFormValues', 'selectedProject', 'fileUploadErrors', 'invalidFilename', 'invalidSeasons', 'criteria' ,
      'invalidTimes', 'invalidSingleDate', 'invalidSingleDayHours', 'invalidMinutesInterval', 'invalidWinds', 'invalidReturnPeriods', 'simulationParametersErrors', 'formUploading', 'jobTypes', 'scenarioSubmissionInProgress']),
  },
  methods: {
    saveSimAssetFiles(){
      this.$emit('saveSimAssetFiles');
    },
    submitImportResults(){
      this.modalStatus +=1;
    }, 
    analysisTypeHasRequiredMetSource(analysisType) {
      let selectedMetSource = this.selectedProject.met_sources.find(source => source.id == this.simulation.met_source_id);
      
      if(analysisType === 'TC') {
        return selectedMetSource?.weather_data_type === 'BOTH';
      } else if(analysisType === 'PLW') {
        return ['WIND', 'BOTH'].includes(selectedMetSource?.weather_data_type);
      } else if(analysisType === 'CLADDING') {
        return selectedMetSource?.custom_met_data?.file_format == 'A3N';
      }
      return true;
    },
    async goBack() {
      this.step = this.createScenarioStep;
      if (this.step == 2) { // Select parameters step
        this.$store.dispatch('project/setBackBtnClicked', true);
        await this.$nextTick();
        this.$store.dispatch('project/setBackBtnClicked', false);
      }
      this.setCreateScenarioStep(--this.step);
      
    },
    goToNext() {
      this.step = this.createScenarioStep;
      if (this.step < 2) {
        this.setCreateScenarioStep(++this.step);
      }
    },
    goToStepZero() {
      this.step = 0;
      this.setCreateScenarioStep(this.step);
    },
    submitScenario() {
      if(this.selectedProject.is_demo_project) return null;
      this.$store.dispatch('project/setCreateScenarioBtnClicked', true);
    },
    getGeometryTypeByNewFilesKey(key) {
      if (key === 'study-file' && this.createdScenarioFormValues['study-file'].length > 0) { return 'study'; }
      if (key === 'surrounds-file' && this.createdScenarioFormValues['surrounds-file'].length > 0) { return 'surround'; }
      if (key === 'ground-file' && this.createdScenarioFormValues['ground-file'].length > 0) { return 'ground'; }
      if (key === 'presentation_plane-file' && this.createdScenarioFormValues['presentation_plane-file'].length > 0) { return 'presentation plane'; }
      if (key === 'landscaping-file' && this.createdScenarioFormValues['landscaping-file'].length > 0) { return 'landscaping'; }
      if (key === 'mitigations-file' && this.createdScenarioFormValues['mitigations-file'].length > 0) { return 'mitigation'; }
      if (key === 'overlay-file' && this.createdScenarioFormValues['overlay-file'].length > 0) { return 'overlay'; }
      return null;
    },
    getFileNames() {
      let fileKeys = Object.keys(this.createdScenarioFormValues);
      let fileNames = [];
      for (let key of fileKeys) {
        if (this.getGeometryTypeByNewFilesKey(key) != null) {
          fileNames.push(this.getGeometryTypeByNewFilesKey(key));
        }
      }

      return fileNames;
    },
    onRemeshComplete(args) {
      if (args.scenario_id.toString() === this.configurationId.toString()) {
        this.remeshNotificationCount += 1;
      }
    },
    onFetchMetComplete(args) {
      const projectId = parseInt(args.project_id);
      if (this.selectedProject.id.toString() === projectId.toString()) {
        for (let metSource of this.selectedProject.met_sources) {
          if (metSource.id == args.met_source_id) {
            metSource.wind_met_data_id = 1; //the actual Id doesn't matter, just set it to 1 so it's truthy.  The actual Id is not used in the front-end
          }
        }
      }
    },
    ...mapActions({
      setCreateScenarioStep: 'project/setCreateScenarioStep'
    })
  },
  watch: {
    step(newValue){
      if(newValue == 2)
        this.$emit('setEditButtonStatus', false);
      else
        this.$emit('setEditButtonStatus', true);
    },
    canSubmitStudyFiles(newValue){
      if(newValue)
        this.gltfErrorMsg = '';
    },
    gltfSubmitStatus(newValue){
      if(typeof newValue == 'string')
        this.gltfErrorMsg = newValue;
    },
    createScenarioStep(newValue, oldValue) {
      //Skip Geo Validation testing && geometry review
      if(oldValue === 0 && newValue === 1 && this.isJobTypeImport){
        this.goToNext();
      } else if (oldValue === 2 && newValue === 1 && this.isJobTypeImport) {
        this.goBack();
      }
    },
    async webSocketConnectionInfo(newValue) {
      if (newValue) {
        await this.connectToWebSocket(newValue);
      }
    },
    simulation(newValue) {
      //reset step when the selected scenario changes
      if (newValue) {
        this.goToStepZero();
      }
    }
  }
};
</script>
<style scoped>

.step-control-color {
  color: #b5b5b5;
}
.steps-container {
  text-align: center;
}

.step-container {
  display: inline-block;
  vertical-align: middle;
  padding-right: 2.188rem;
  margin-top: 0;
}

.loading-container {
  display: inline-block;
  vertical-align: middle;
  margin-top: 0;
  position: absolute;
  right: 90%;
}

p {
  color: white;
  font-size: 0.7em;
  display: inline;
  padding-left: 0.625rem;
}

.bottom-right-above-submit-btn {
  right: 0.625rem;
  bottom: 3.563rem;
  position: absolute;
}

.inactive {
  color: var(--grey-700);
}
</style>

