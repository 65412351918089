<template>
  <b-modal id="importResultModal" centered hide-header-close title="Confirm Submission" :hide-footer=isSubmitting>
    <p  v-if="!isSubmitting"> Are you sure you wish to submit these simulation results ? </p>
    <div  v-else class="text-center">
      <b-spinner></b-spinner>
    </div>
    <template #modal-footer>
        <b-button @click='$bvModal.hide("importResultModal")'  class='float-left'> Cancel </b-button>    
        <b-button variant="primary" @click="confirmSubmission()" class='float-right'> Submit </b-button>    
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ImportResultsConfirmation',
  props: {
    modalStatus: {
      type: Number,
      required: false
    },
  },
  data(){
    return {
      isSubmitting: false
    };
  },
  computed: {
    showSpinner(){
      return this.isSubmitting;
    }
  },
  methods: {
    handleCancel(){
      this.$bvModal.hide('importResultModal');
    },
    confirmSubmission(){
      this.isSubmitting = true;
      this.$emit('saveSimAssetFiles'); 
    }
  },
  watch: {
    modalStatus(oldValue, newValue){
      if(oldValue != newValue)
        this.$bvModal.show('importResultModal');
    }
  }

};
</script>
<style scoped>
  .import-results-container {
    padding: 1.25em;
    margin: 0;
    position: absolute;
    height: 65%;
    width: 76%;
    top: calc(25% - 0.313rem);
    background-color: var(--grey-100);
    left: 11%;
    border-top-right-radius: 1.563rem;
    border-top-left-radius: 1.563rem;
    overflow-y: scroll;
    z-index: 2;
  }
</style>
<style>
  
</style>
